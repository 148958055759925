import React, { useState, useEffect } from "react";
import axios from "axios";
import LocalStorageService from "../libs/LocalStorageService";
import { startOfDay, endOfDay } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { useHistory } from "react-router-dom";
import Filters from "../components/Filters";
import VerificationsTable from "../components/VerificationsTable";
import CustomEmpty from "../components/CustomEmpty";
import ErrorComponent from "../components/ErrorComponent";
import { BECOME_API_URL } from "../libs/utils";

const Verifications = () => {
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState();
  const [rangeDate, setRangeDate] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [textToFilter, setTextToFilter] = useState('');
  const [countVerifications, setCountVerifications] = useState({
    "verificacionesCompletadas": 0,
    "verificacionesPendientes": 0,
    "verificacionesError": 0
  });
  const [tokenInfo, setTokenInfo] = useState();
  const [percentage, setPercentage] = useState(0);
  const [progress, setProgress] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const token = LocalStorageService.getAccessToken();

  useEffect(() => {
    fetchData(page + 1);
    // eslint-disable-next-line
  }, [textToFilter, rangeDate, page]);

  useEffect(() => {
    const tokenInfo = JSON.parse(atob(token.split(".")[1])).identity;
    setTokenInfo(tokenInfo);
  }, [token]);

  const updateTablePicker = (filter) => {
    let newData = [];
    if (filter && filter.length > 0) {
      setRangeDate(filter)
      let stDate = Date.parse(startOfDay(filter[0]));
      let endDate = Date.parse(endOfDay(filter[1]));
      console.log(endDate)
      newData = data.filter((dt, i) => {
        let dDate = Date.parse(dt.created_at);
        return dDate >= stDate && dDate <= endDate;
      });
      return setFilteredData(newData);
    }
    setRangeDate([])
    return setFilteredData(data);
  };

  const fetchData = (page = 1) => {
    setIsLoading(true);
    let params = {
      page: page,
      ...(textToFilter && { query_search: textToFilter.toUpperCase() })
    };

    if (rangeDate) {
      params = {
        start_date: rangeDate[0],
        end_date: rangeDate[1],
        page: page,
        ...(textToFilter && { query_search: textToFilter.toUpperCase() })
      };
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    };

    axios
      .get(BECOME_API_URL + "/identity", config)
      .then((result) => {
        if (result.status === 200) {
          setData(result.data.data);
          setFilteredData(result.data.data);
          setCountVerifications({
            ...countVerifications,
            "verificacionesCompletadas": result.data.data.filter((row) => row.verification_status === 'completed').length,
            "verificacionesPendientes": result.data.data.filter((row) => row.verification_status === 'pending').length,
            "verificacionesError": result.data.data.filter((row) => row.verification_status === 'error').length,
          })
          setTotalData(result.data.total)
          setHasError(false);
        }
        setIsLoading(false);
      })

  };

  const history = useHistory();

  const detailRedirect = (companyId, userId) => {
    history.push(`/verification/${companyId}/${userId}`);
  };

  const filterByStatus = (el) => {
    let newData = [];
    let filters = [];

    if (el.target.checked) {
      filters.push(el.target.value);
    } else {
      filters.pop(el.target.value)
    }

    if (filters.length > 0) {
      newData = data.filter(dt => dt.verification_status.includes(filters));
      return setFilteredData(newData);
    }
    return setFilteredData(data);
  };

  const searchIdentites = (e) => {
    if (e.keyCode === 13) {
      setPage(0);
      setTextToFilter(e.target.value);
    } else if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value.length <= 1) {
      setPage(0);
      setTextToFilter("");
    }
  }

  // const sortByDate = () => {
  //   let dir = document.getElementById("sotByDate").value;
  //   let newData = [];

  //   if (dir === 'asc') {
  //     newData = data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //     return setFilteredData(newData);
  //   } 

  //   if (dir === "desc" ) {
  //     newData = data.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  //     return setFilteredData(newData);
  //   }
  //   return setFilteredData(data);
  // };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleDownloadAll = () => {
    let progress = 0;
    const today = new Date();
    const defaultStartDate = new Date(new Date().setDate(today.getDate() - 30));
    defaultStartDate.setHours(0, 0, 0, 0);
    const defaultEndDate = today;
    defaultEndDate.setHours(0, 0, 0, 0);
    setSnackOpen(true);

    setProgress('preparation');

    axios
      .post(`${BECOME_API_URL}/download`, null, {
        headers: { Authorization: `Bearer ${token}`, responseType: "blob" },
        params: {
          start_date: rangeDate[0] || defaultStartDate,
          end_date: rangeDate[1] || defaultEndDate,
          page: 1,

          ...(textToFilter && { query_search: textToFilter.toUpperCase() })
        },
        onDownloadProgress(progressEvent) {
          progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          if (progress > 0) {
            setProgress('in-progress');
          }

          setPercentage(progress);
        }
      })
      .then((res) => {
        setProgress('finished');
        const blob = new Blob([res.data], { type: "type/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "download.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(() => setProgress(null), 1000)
        setSnackOpen(false);
      })
      .catch((e) => console.log(e));
  };

  return (
    hasError ? <ErrorComponent /> :
      <React.Fragment>
        <Filters
          tokenInfo={tokenInfo}
          filterByStatus={filterByStatus}
          searchIdentites={searchIdentites}
          setRangeDate={setRangeDate}
          setPage={setPage}
          updateTablePicker={updateTablePicker}
          handleDownloadAll={handleDownloadAll}
          percentage={percentage}
          progress={progress}
          open={snackOpen}
          handleSnackClose={handleSnackClose} />

        {(!data || totalData <= 0) ? <CustomEmpty sx={{ marginTop: '1rem' }} /> :
          <VerificationsTable
            detailRedirect={detailRedirect}
            page={page}
            setPage={setPage}
            tokenInfo={tokenInfo}
            data={filteredData}
            count={totalData}
            fetchData={fetchData}
            isLoading={isLoading} />
        }
      </React.Fragment>
  );
};

export default Verifications;
