import React, { useState, useEffect } from 'react';

const ImageLoad = React.memo(({ index, src, placeholder, alt = "", action = null }) => {
    const [loading, setLoading] = useState(true);
    const [currentSrc, updateSrc] = useState(placeholder);

    useEffect(() => {
        // start loading original image
        const imageToLoad = new Image();
        imageToLoad.src = src;
        imageToLoad.onload = () => {
            // When image is loaded replace the src and set loading to false
            setLoading(false);
            updateSrc(src);
        }
    }, [src])

    return (
        <figure style={{ cursor: "pointer" }} className="image is-3by2">
            <img
                className='is-clickable'
                src={currentSrc}
                style={{
                    opacity: loading ? 0.5 : 1,
                    transition: "opacity .15s linear",
                    objectFit: "scale-down", // Change to scale-down if required
                    height: "230px"
                }}
                alt={alt}
                data-index={index}
                onClick={action}
            />
        </figure>
    )
});

export default ImageLoad;