import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import LocalStorageService from '../libs/LocalStorageService';
import { Loader } from 'rsuite';
import VerificationInfo from "../components/VerificationInfo";
import ErrorComponent from '../components/ErrorComponent';
import { BECOME_API_URL } from '../libs/utils';

const Verification = () => {
  const [data, setData] = useState({});
  const [hasError, setHasError] = useState(false);
  const { companyId, id } = useParams();
  // const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    const token = LocalStorageService.getAccessToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    };

    axios.get(`${BECOME_API_URL}/identity/${id}?company_id=${companyId}`, config).then((result) => {
      if (result.status === 200 || result.status === 202) {
        setData(result.data);
        setHasError(false);
      } else {
        setHasError(true);
      }
    }).catch((e) => {
      console.log(e);
      setHasError(true);
    });
    // eslint-disable-next-line
  }, [id]);

  return (<main className="main">
    {hasError ? <ErrorComponent /> :
      Object.keys(data).length === 0 ?
        <div className="bcLoader">
          <Loader size="lg" content="" />
        </div> :
        <VerificationInfo data={data} setData={setData} companyId={companyId} id={id} />
    }
  </main>)
}

export default Verification;