import Button from "@mui/material/Button";
import React from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const Picker = ({ setRangeDate, updateTablePicker, setPage }) => {
  const { afterToday, allowedMaxDays, combine } = DateRangePicker;
  return (
    <Button>
      <DateRangePicker
        onChange={(result) => {
          if(result) {
            let startTime = new Date(result[0])
            let endTime = new Date(result[1])
            startTime.setUTCHours(5, 0, 0, 0);
            endTime.setDate(endTime.getDate() + 1)
            endTime.setUTCHours(4,59,59,999);
            let res = [startTime, endTime]
            setRangeDate(res);
            updateTablePicker(res)
          } else {
            setRangeDate(result);
            updateTablePicker(result)
          }
          setPage(0);
        }}
        showOneCalendar
        placeholder="Fecha Inicio - Fecha Fin"
        disabledDate={combine(afterToday(), allowedMaxDays(30))}
      />
    </Button>
  );
};

export default Picker;
