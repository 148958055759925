import React from 'react'
import { Button, Container, Paper, Stack, Typography } from '@mui/material';
import CustomEmpty from './CustomEmpty';

const ErrorComponent = () => {
    return (
        <Container maxWidth="md">
            <Paper sx={{ padding: "2rem" }}>
                <Stack spacing={4} alignItems="center">
                    <CustomEmpty />
                    <Typography variant='h5'>Oh!, a ocurrido un error. Regresa al dashboard o intentalo más tarde.</Typography>
                    <Button href='/dashboard' variant="outlined">Go to dashboard</Button>
                </Stack>
            </Paper>
        </Container>
    );
}


export default ErrorComponent;