import React from "react";

const NotFound = () => {
    return (<div className="NotFound">
        <div className="hero is-fullheight">
            <div class="hero-body">
            <div class="container has-text-centered">
                <div class="columns is-centered">
                <div class="column is-7">
                    <h1 class="title is-1">404</h1>
                    <p class="subtitle is-3">Page not found</p>
                    <p>The identity of a person is his way of being in society and in his own world.</p>
                </div>
                </div>
                <a class="button is-primary" href="/dashboard">Back to Dashboard</a>
            </div>
            </div>
        </div>
    </div>);
}

export default NotFound;