import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { AuthContext } from "./libs/Auth";
import LocalStorageService from "./libs/LocalStorageService";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/SignIn";
import NotFound from "./pages/NotFound";

function App() {
  const existingTokens = LocalStorageService.getAccessToken();
  const [authTokens, setAuthTokens] = useState(existingTokens);
  let loggedIn = false;

  if (
    existingTokens &&
    !LocalStorageService.isExpired(
      LocalStorageService.getExpirationDate(existingTokens)
    )
  ) {
    loggedIn = true;
  }

  const setTokens = (data) => {
    LocalStorageService.setToken(data);
    setAuthTokens(data);
  };

  return (
    <AuthContext.Provider
      value={{ authTokens, setAuthTokens: setTokens, isLoggedIn: loggedIn }}
    >
      <Router>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
