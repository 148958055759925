import React from 'react';
import LocalStorageService from "../libs/LocalStorageService";
import "./Profile.css";
import Container from "@mui/material/Container";
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { Grid, List, ListItem, ListItemText, Paper, Typography, Stack } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import { CCandREC } from '../libs/utils';

const Profile = () => {
  const token = LocalStorageService.getAccessToken();
  const data = JSON.parse(window.atob(token.split(".")[1])).identity;

  return (
    <Container maxWidth="xl" className='Profile'>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper>
            <Stack direction='row' spacing={2} sx={{ p: 1 }}>
              <Avatar alt={data.client_id}>
                <VerifiedUser />
              </Avatar>
              <Typography variant='h6'>Datos de Cuenta</Typography>
            </Stack>           

            <Divider ></Divider> 
            <List sx={{ width: '100%', maxWidth: 360 }}>
              <ListItem>
                <ListItemText>
                  Name: {CCandREC(data.client_id)}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Username: {data.client_id}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  ID Compañia: {data.company_id}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Role: {data.role_id}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Reviewer: {data.is_reviewer ? "Sí" : 'No'}
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Stack direction='row' spacing={2} sx={{ p: 1 }}>
              <Avatar>
                <InfoIcon />
              </Avatar>
              <Typography variant='h6'>Información</Typography>
            </Stack>  
            
            <Divider ></Divider>
            <List sx={{ width: '100%', maxWidth: 360 }}>
              <ListItem>
                <ListItemText>
                  País: {data.company_info.company_country || "NA"}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Estado: {data.company_info.company_state || "NA"}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Dirección: {data.company_info.company_address || "NA"}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Teléfono: {data.company_info.company_phone || "NA"}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Representante Lega: {data.company_info.company_legal_representative || "NA"}
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Profile;