import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Picker from './Picker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { red, yellow, green } from '@mui/material/colors';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Filters({ filterByStatus, searchIdentites, updateTablePicker, setRangeDate, handleDownloadAll, progress, percentage, handleClose, open, setPage, tokenInfo }) {
    return (
        <Paper sx={{ padding: '0.5rem 0', marginBottom: '0.75rem' }}>
            <Container maxWidth="xl">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={2} lg={3}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                                <InputLabel htmlFor="searchInput">Buscar</InputLabel>
                                <OutlinedInput
                                    id="searchInput"
                                    type="text"
                                    onKeyDown={searchIdentites}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    }
                                    label="Buscar"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5} lg={5} textAlign="center">
                            <FormControlLabel control={<Checkbox sx={{ color: green[600], '&.Mui-checked': { color: green[600] } }} onChange={filterByStatus} name="completed" value="completed" />} label="Completadas" />
                            <FormControlLabel control={<Checkbox sx={{ color: yellow[600], '&.Mui-checked': { color: yellow[600] } }} onChange={filterByStatus} name="pending" value="pending" />} label="Pendientes" />
                            <FormControlLabel control={<Checkbox sx={{ color: red[600], '&.Mui-checked': { color: red[600] } }} onChange={filterByStatus} name="error" value="error" />} label="Errores" />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Picker updateTablePicker={updateTablePicker} setRangeDate={setRangeDate} setPage={setPage} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={1} justifyItems="end">
                            {(tokenInfo?.permission === null || tokenInfo?.permission === undefined || tokenInfo?.permission === 2) && (
                                <Button variant='outlined' onClick={handleDownloadAll} >
                                    {progress === 'finished' ? '🎉 Listo' : progress === 'preparation' ? 'Procesando' : 'Descargar'}
                                </Button>
                            )}
                            
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                    {progress === 'in-progress' ? `Descargando ${percentage}%` : progress === 'preparation' ? 'Generando csv' : 'Max 30 días'}
                                </Alert>
                            </Snackbar>

                        </Grid>
                    </Grid>
                </Box >
            </Container>
        </Paper>

    );
}
