import React from 'react'
import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
// Old icons
import EditIcon from '@rsuite/icons/Edit';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import SearchPeopleIcon from '@rsuite/icons/SearchPeople';
import PageIcon from '@rsuite/icons/Page';
// New icons
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';

function Regular({ urlBase, riesgo, data, companyId }) {
    return <>
        <Card padding={2}>
            <CardHeader>
                <Typography variant="h5" color="text.secondary">
                    {data.identity_status}
                </Typography>
            </CardHeader>
            <CardContent>
                <div className="information">
                    <Typography><strong>Nombre Completo:</strong> {data.fullname || ""}</Typography>
                    <Typography><strong>Fecha Nacimiento:</strong>  {data.birth ? data.birth.split(" 00:")[0] : ""}</Typography>
                    <Typography><strong>N° Documento:</strong>  {data.document_number || ""}</Typography>
                    {data.phone_number && <Typography><strong>Número de teléfono:</strong> {data.phone_number}</Typography>}
                    {data.email_address && <Typography><strong>E-mail:</strong> {data.email_address}</Typography>}
                    {data.estimated_age && <Typography><strong>Edad Est. Selfie:</strong> {data.estimated_age}</Typography>}
                    {data.dni_number && <Typography><strong>DNI:</strong> {data.dni_number}</Typography>}
                    {data.email_address && <Typography><strong>Email:</strong> {data.email_address}</Typography>}

                    <Typography><strong>Verificado:</strong>  {data?.created_at ? new Date(data.created_at).toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                    }) : ""}</Typography>
                    {data.error_code && <Typography><strong>Información adicional:</strong>  {data.error_code}</Typography>}
                </div>
                <section>
                    <div className="columns">
                        {data.verification.alteration != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Detección de alteraciones</strong></Typography>
                                                    {data.verification.alteration ? (
                                                        <Typography className="has-text-success">No alterado</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">Alterado</Typography>
                                                    )}
                                                </div>
                                            </div>
                                            {/*  */}
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification.alteration ? (
                                                    <EditIcon className="icon has-text-success" />
                                                ) : (
                                                    <EditIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                        {data.verification.face_match != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Facematch</strong></Typography>

                                                    {data.verification.face_match ? (
                                                        <div>
                                                            <Typography className="has-text-success">Coincide: {data.face_match_score ? (<strong>{data.face_match_score} % </strong>) : null}</Typography>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Typography className="has-text-danger">No coincide: {data.face_match_score ? (<strong>{data.face_match_score} % </strong>) : null}</Typography>
                                                        </div>

                                                    )}
                                                </div>
                                            </div>
                                            {/*  */}
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification.face_match ? (
                                                    <SearchPeopleIcon className="icon has-text-success" />
                                                ) : (
                                                    <SearchPeopleIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                    </div>
                    <div className="columns">
                        {data.verification.template != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Revisión de tipo de documento</strong></Typography>
                                                    {data.verification.template ? (
                                                        <Typography className="has-text-success">Coincide</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No Coincide</Typography>
                                                    )}
                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification.template ? (
                                                    <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                ) : (
                                                    <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                        {data.verification.watch_list != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Listas Negras</strong></Typography>
                                                    {(data.verification.watch_list || data.fullname == null) ? (
                                                        <Typography className="has-text-success">No encontrado</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">Encontrado</Typography>
                                                    )}
                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {(data.verification.watch_list || data.fullname == null) ? (
                                                    <PageIcon className="icon has-text-success" />
                                                ) : (
                                                    <PageIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                    </div>
                    <div className="columns">
                        {data.verification.estimated_age != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Validación de Estimación de Edad</strong></Typography>
                                                    {data.verification.estimated_age ? (
                                                        <Typography className="has-text-success">Coincide</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No Coincide</Typography>
                                                    )}
                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification.estimated_age ? (
                                                    <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                ) : (
                                                    <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {data.verification.email_deliverable != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Revisión de Email Entregable</strong></Typography>
                                                    {data.verification.email_deliverable ? (
                                                        <Typography className="has-text-success">Entregable</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No Entregable</Typography>
                                                    )}
                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification.email_deliverable ? (
                                                    <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                ) : (
                                                    <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                        {data.verification.email_safe != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Revisión de Email Seguro</strong></Typography>
                                                    {data.verification.email_safe ? (
                                                        <Typography className="has-text-success">Seguro</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No Seguro</Typography>
                                                    )}
                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification.email_safe ? (
                                                    <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                ) : (
                                                    <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                        {data.verification.one_to_many_result != null ? (
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>1 a N</strong></Typography>
                                                    {data.verification.one_to_many_result ? (
                                                        <Typography className="has-text-success">Seguro</Typography>
                                                    ) : (
                                                        <div>
                                                            <a target="_blank" className="button is-danger is-small mt-2" href={`${urlBase}verification/${companyId}/${data.one_to_many_user_id_matched}`} rel="noopener noreferrer">Coincidencia Encontrada</a>
                                                        </div>

                                                    )}
                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification.one_to_many_result ? (
                                                    <SearchPeopleIcon className="icon has-text-success" />
                                                ) : (
                                                    <SearchPeopleIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}

                        {data?.phone_score_validation &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Riesgo Teléfono</strong></Typography>
                                                    <Typography className={`riesgo-${riesgo}`}>
                                                        {riesgo}
                                                    </Typography>
                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                <EditIcon className={`icon riesgo-${riesgo} text-right`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div className="columns">
                        {data.ocr_validation?.alteraciones?.alt_registrador &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Registrador</strong></Typography>
                                                    {data.ocr_validation.alteraciones.alt_registrador ? (
                                                        <Typography className="has-text-success">No alterado</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">Alterado</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.ocr_validation.alteraciones.alt_registrador ? (
                                                    <EditIcon className="icon has-text-success" />
                                                ) : (
                                                    <EditIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        {data.ocr_validation?.alteraciones?.alt_sexo &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Sexo</strong></Typography>
                                                    {data.ocr_validation.alteraciones.alt_sexo ? (
                                                        <Typography className="has-text-success">No Alterado</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">Alterado</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.ocr_validation?.alteraciones?.alt_sexo ? (
                                                    <EditIcon className="icon has-text-success" />
                                                ) : (
                                                    <EditIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        {data.ocr_validation?.alteraciones?.alt_dates &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Fechas</strong></Typography>
                                                    {data.ocr_validation.alteraciones.alt_dates ? (
                                                        <Typography className="has-text-success">No alterado</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">Alterado</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.ocr_validation.alteraciones.alt_dates ? (
                                                    <EditIcon className="icon has-text-success" />
                                                ) : (
                                                    <EditIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        {data.ocr_validation?.alteraciones?.alt_mayor_de_edad &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Mayor de edad</strong></Typography>
                                                    {data.ocr_validation.alteraciones.alt_mayor_de_edad ? (
                                                        <Typography className="has-text-success">No alterado</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">Alterado</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.ocr_validation.alteraciones.alt_mayor_de_edad ? (
                                                    <EditIcon className="icon has-text-success" />
                                                ) : (
                                                    <EditIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        {data.verification?.liveness !== null &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Liveness</strong></Typography>
                                                    {data.verification?.liveness ? (
                                                        <Typography className="has-text-success">Pasó</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No pasó</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data.verification?.liveness ? (
                                                    <PersonIcon className="icon has-text-success" />
                                                ) : (
                                                    <PersonOffIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {data?.blacklist && data?.blacklist?.result !== null &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Blacklist Facial</strong></Typography>
                                                    {data?.blacklist?.result ? (
                                                        <Typography className="has-text-success">Pasó</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No pasó</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data?.blacklist?.result ? (
                                                    <PersonIcon className="icon has-text-success" />
                                                ) : (
                                                    <PersonOffIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {data?.ocr_validation?.alteraciones?.alt_face_age && data?.ocr_validation?.alteraciones?.alt_face_age.result !== null &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Edad Foto Cédula</strong></Typography>
                                                    {data?.ocr_validation?.alteraciones?.alt_face_age.result ? (
                                                        <Typography className="has-text-success">Pasó - {data?.ocr_validation?.alteraciones?.alt_face_age?.compared_age}</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No pasó - {data?.ocr_validation?.alteraciones?.alt_face_age?.compared_age}</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data?.blacklist?.result ? (
                                                    <PersonIcon className="icon has-text-success" />
                                                ) : (
                                                    <PersonOffIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {data?.ocr_validation?.age_range &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Edad Est. Foto Cédula</strong></Typography>
                                                    {data?.ocr_validation?.age_range?.low && data?.ocr_validation?.age_range?.high && (
                                                        <Typography>
                                                            Min: {data?.ocr_validation?.age_range?.low} - Max: {data?.ocr_validation?.age_range?.high}
                                                        </Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">

                                                {/* <PersonIcon className="icon has-text-success" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {data?.brasil && data?.brasil?.biometria_face !== null &&
                            <div className="column">
                                <div className="box">
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <div>
                                                    <Typography><strong>Serpro Face</strong></Typography>
                                                    {data?.brasil?.biometria_face_score > 0.8 ? (
                                                        <Typography className="has-text-success">Coincide: {data.brasil.biometria_face_score ? (<strong>{`${Math.round((data.brasil.biometria_face_score * 100) * 100) / 100})`} % </strong>) : null}</Typography>
                                                    ) : (
                                                        <Typography className="has-text-danger">No Coincide</Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="level-rigth mr-3">
                                            <div className="level-item">
                                                {data?.brasil?.biometria_face ? (
                                                    <PersonIcon className="icon has-text-success" />
                                                ) : (
                                                    <PersonOffIcon className="icon has-text-danger" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </CardContent>
        </Card>
    </>;
}

export default Regular