import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#10a2a3',
        },
        secondary: {
            main: '#10a2a3',
            "&:hover": {
                
            }
        },
    },
    typography: {
        button: {
            textTransform: 'capitalize'
        }
    },
});

export const darkTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#061634',
        },
        secondary: {
            main: '#061634',
            "&:hover": {

            }
        },
    },
    typography: {
        button: {
            textTransform: 'capitalize'
        }
    },
});