import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(data) {
  return {
    id: data["id"],
    maxVerifications: data["maxVerifications"],
    countVerifications: data["countVerifications"],
    details: [
      {
        id: 'Verifications',
        canUse: data["canSendVideo"],     
        max: data["maxVerifications"],
        amount: data["countVerifications"]
      },
      {
        id: 'ComplyAdvantage',
        canUse: data["canUseComply"],
        max: data["maxIsComply"],
        amount: data["countIsComply"]
      },
      {
        id: 'OneToOne',
        canUse: data["canUseOnexOne"],
        max: data["maxIsOnexOne"],
        amount: data["countIsOnexOne"]
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{row.id}</TableCell>
        <TableCell align="right">{row.maxVerifications}</TableCell>
        <TableCell align="right">{row.countVerifications}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalles del contrato:
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Servicio Habilitado</TableCell>
                    <TableCell>Usado</TableCell>
                    <TableCell>Maximo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detail) => (
                    <TableRow key={detail.id}>
                      <TableCell>{detail.id}</TableCell>
                      <TableCell>{detail.canUse ? "SI" : "NO"}</TableCell>
                      <TableCell align="right">{detail.amount}</TableCell>
                      <TableCell align="right">{detail.max}</TableCell>                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    maxVerifications: PropTypes.number.isRequired,
    countVerifications: PropTypes.number.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        canUse: PropTypes.bool.isRequired,
        max: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default function ContractsTable({ data }) {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    Object.entries(data).map(el => setRows(rows => [createData(el[1]), ...rows]))
  }, [data])

  return (
    rows.length > 0 && (
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption>
            <strong>Nota:</strong> En el apartado Verifications, Servicio Habilitado indica si el contrato tiene la opción de enviar vídeo.</caption>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID Contrato</TableCell>
            <TableCell align="right">Max Verificaciones</TableCell>
            <TableCell align="right">Conteo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>)
  );
}
