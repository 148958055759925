import React, { useEffect, useState } from 'react';
import axios from "axios";
import LocalStorageService from '../libs/LocalStorageService';
import ContractsTable from '../components/ContractsTable';
import CustomEmpty from "../components/CustomEmpty"
import ErrorComponent from '../components/ErrorComponent';
import { BECOME_API_URL } from '../libs/utils';

const Contracts = (props) => {
  const [data, setData] = useState();
  const [hasError, setHasError] = useState(false);

  const token = LocalStorageService.getAccessToken();

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    const getData = () => {
      axios
        .get(BECOME_API_URL + "/contracts", config)
        .then((result) => {
          if (result.status === 200) {
            setData(result.data);
            setHasError(false);
          } else {
            setHasError(true);
          }
        })
        .catch((e) => {
          console.log(e);
          setHasError(true);
        });
    }

    getData();
  }, [config]);

  return (
    <main>
      {hasError ? <ErrorComponent /> : data ?
        <ContractsTable data={data} />
        :
        <div className="columns is-multiline mt-3 notification is-white">
          <div className="column is-full">
            <CustomEmpty />
          </div>
        </div>}
    </main>
  );
}

export default Contracts;
