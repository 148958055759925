import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Button from '@mui/material/Button';

import LocalStorageService from "../libs/LocalStorageService";
import { useHistory } from "react-router-dom";
import { useAuth } from "../libs/Auth";

const Navbar = () => {
    const history = useHistory();
    const contextData = useAuth();
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        LocalStorageService.clearToken();
        contextData.isLoggedIn = false;
        history.push("/");
    };

    const settings = [
        { title: 'Profile', url: "/dashboard/profile" },
        { title: 'Contracts', url: "/dashboard/contracts" },
        { title: 'Logout', action: handleLogout}
    ];

    return (
        <AppBar color="secondary" position="sticky" sx={{ marginBottom: "20px", padding: "0.25rem"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <Button href='/dashboard' className="dashboard-btn" color="inherit">
                            <DashboardIcon sx={{ display: 'flex', mr: 1 }} />
                            <Typography>Dashboard</Typography>
                        </Button>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0}}>
                                <Avatar sx={{ bgcolor: 'inherit' }}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.title} onClick={handleCloseUserMenu}>
                                    <Link color='secondary' underline="none" textAlign="center" href={setting?.url} onClick={setting?.action}>{setting.title}</Link>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Navbar;
