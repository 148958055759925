export const getParams = function (url) {
    let params = {};
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

export const CCandREC = (str) => str.replace(/[^a-z0-9]*([a-z0-9])([a-z0-9]*)/ig, (m, u, l) => u.toUpperCase() + l.toLowerCase() + ' ').trim()
export const BECOME_API_URL = 'https://becomedigital-api.azurewebsites.net/api/v1';