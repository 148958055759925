
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./libs/Auth";
import LocalStorageService from './libs/LocalStorageService';

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useAuth();
  
  let token = null;
  if(isAuthenticated.authTokens){
    token = LocalStorageService.getAccessToken(); 
  }
  
  return (
    <Route
      {...rest}
      render={props =>

        token && !LocalStorageService.isExpired(LocalStorageService.getExpirationDate(token)) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default PrivateRoute;